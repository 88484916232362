import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AffPes from "../components/pes2021cta"

const bestPlayers = () => {
  return (
    <Layout>
      <SEO
        title="Best Gold, Silver and Bronze Players in PES 2021"
        description="Find out who the best Gold, Silver and Bronze Balls are in PES 2021 myClub."
      />
      <div className="container">
        <h1>The best Gold, Silver and Bronze Players in PES 2021</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Best Players</li>
        </ul>
        <p>
          Here are my favorite players in PES 2021. This is a very subjective
          matter - you probably have different players you enjoy. But I hope you
          find a gem or two!
        </p>
        <p>
          This became a rather extensive list, so this year I have decided to
          group them by Ball Type:
        </p>
        <ul>
          <li>
            <Link to="/best-gold-ball-players/">Best Gold Ball Players</Link>
          </li>
          <li>
            <Link to="/best-silver-ball-players/">
              Best Silver Ball Players
            </Link>
          </li>
          <li>
            <Link to="/best-bronze-ball-players/">
              Best Bronze Ball Players
            </Link>
          </li>
        </ul>
        <p>
          <small>
            If you're looking for the{" "}
            <Link to="/best-players-2020/">
              best players in PES 2020, go here
            </Link>
            .
          </small>
        </p>
        <p>
          You're probably asking: "Why are Black and White Ball Players
          missing?". The reason is simple.
        </p>
        <p>
          With so many Featured Players available this year, I decided to
          exclude Black Ball players from this list. Getting them depends on
          luck, and the others I would recommend are the usual suspects (like
          Ronaldo or Van Dijk). They also have less levels to achieve their max
          potential, which to me is one of the fun aspects of myClub.
        </p>
        <p>
          As for White Ball players, since I don't spend enough time training or
          playing with them, I don't feel comfortable in recommending any.
        </p>
      </div>
      <AffPes />
      <div className="container">
        <p>
          I'm not listing these players based on their training potential, or
          Max Overall. These are players that I own/are on my radar, due to
          their attributes, skills and style.
        </p>
        <p>
          I have included pictures of all of them, so that you can have a look
          at their stats.
        </p>
        <p>
          Don't forget to visit{" "}
          <Link to="/player-attributes/">Player Attributes</Link>,{" "}
          <Link to="/player-skills/">Player Skills</Link>, and{" "}
          <Link to="/playing-styles/">Playing Styles</Link> if something is
          unclear to you.
        </p>
      </div>
    </Layout>
  )
}

export default bestPlayers
